
.form_name, .form_email, .form_phone, .select-insurance{
  padding:10px !important;
	font-family:'Raleway' !important;
	font-size:20px !important;
   color: #A1A1A1 !important;
	border: 1px solid #61D68E !important;
}
.form-1 button{
  background: #3F89C8 !important;
border-radius: 5px;
	border:0px;
	font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.02em;
width:100%;
padding:16px;

color: #FFFFFF;
}

input[type=email], input[type=number],  input[type=tel], input[type=text], select{
  width: 100%;
  margin-bottom: 24px;
}
.formWord{
  border: 1px solid #61d68e;
  margin:20px;
  padding: 35px;
  text-align: center;
}
.formWord>label{
  font-size:16px;
  font-weight:400;
  margin-top: 20px;
}

input[type=checkbox]{
  margin-right:10px;
}

label{
  font-weight: 400;
  font-family: "Raleway",sans-serif;
  display: contents;
}

label a{
  color:blue;
}

.form-1{
  text-align: left;
}