.top-bar{
  background:linear-gradient(
    270deg, #243B71 -4.69%, #3C80BF 106.25%); ;
}

        .bg-myRed{
          background-color: crimson;
        }

        .content{
          padding: 12px;
          height: 3000px;
        }

        .coloring{
          color: black;
        }

        .navbar-brand{
                  height:auto;

        }

        .navbar-light .navbar-nav .nav-link{
                  color:black;
                  font-size: 16px;
                  font-weight: 600;
                  font-family: 'Raleway', sans-serif;
                  color: black;
        }

        .navbar-light .navbar-nav .nav-link:hover{
                  color:#3F89C8;
        }

        .navbar-light .navbar-nav .nav-link.active{
          color:#3F89C8;
        }

        .right-aligned{
                  justify-content: flex-end;
        }

        .navbar{
                  margin-bottom: 0px;
                  padding: 0px;
        }



      @media(max-width:768px){
        .navbar-brand{
          margin-right: 0px;
        }
      }