* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif
}

#accordian-new h2 {
  text-align: center;
}

.accordion {
  font-family: "Raleway", sans-serif;
  font-weight:700px;
  font-size: 22px;
  margin: 0 20px;
  color: #000;
}

#accordian-new{
  padding-top: 50px;
  padding-bottom:50px;
}

.accordian-main{
  box-shadow: 0px 6px 24px rgb(27 27 27 / 20%);

}
.accordion__button {
  border: 0;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
  text-align: left;
  font-weight: 700;
  background-color: white;
}

.accordion__button span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
}

.accordion__button:focus {
  outline: none;
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  transition: all .5s;
  height: 0;
  margin: 0;
  margin-top: -10px;
  border: 1px solid #f1f1f1;
  border-top: 0;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
}