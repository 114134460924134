
#hero-becomeposp{
          padding-top: 50px;
          padding-bottom: 50px;
}

@media(min-width:768px){
.flex-becomeposp{
  display: flex;
}
}
#posp-heading{
          flex: 60%;
}


.contactme{
          flex: 40%;
}

.form_name, .form_email, .form_phone{
  padding:10px !important;
	font-family:'Raleway' !important;
	font-size:20px !important;
   color: #A1A1A1 !important;
	border: 1px solid #61D68E !important;
}
#form_button{
  background: #F89748 !important;
border-radius: 5px;
	border:0px;
	font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.02em;
width:100%;
padding:16px;

color: #FFFFFF;
}

input[type=email], input[type=number],  input[type=tel], input[type=text]{
  width: 100%;
  margin-bottom: 24px;
}
.formWord{
  border: 1px solid #61d68e;
  margin:20px;
  padding: 35px;
  text-align: center;
}
.formWord>label{
  font-size:16px;
  font-weight:400;
  margin-top: 20px;
}

input[type=checkbox]{
  margin-right:10px;
}
/** Benfits of POSP **/

@media (min-width:768px){

  .benefitsposp-flex{
    display: grid;
    text-align: center !important;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 60px;
  }

}

  .benefitsposp-flex1{
    text-align: center;
  }

  .benefitsposp-heading{
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    line-height: 45px;
  }

  #benefits-posp{
    background-color: #E2E9F1;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .benefitsposp-flex1> p{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 18px;
    margin-top: 20px;
    font-family: "Raleway", sans-serif;
  }


 /* Steps Section */

#steps-posp{
  padding:50px 0px 50px 0px;
}

.stepposp-heading{
  text-align: center;
}
.steps{
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: #3F89C8;
  margin-top: -100px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

#step-button{
  background-color: #3F89C8;
  border:none;
  color: white;
  font-family: "Raleway", sans-serif;
  padding: 10px;
  border-radius: 6px;
}

@media(min-width:768px){
  .stepposp-gridA{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    text-align: center;
    margin-top: 50px;
    height: 300px;
  }

  .stepposp-gridB{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-right: 15%;
    margin-left: 15%;
    text-align: center;
  }

}


.stepposp-grid1{
  margin:60px;
  padding:60px;
  border: 1px solid #2cbc99;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #2CBC99;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 6px;
}

  /** review **/
  #review-section{
    padding: 50px 0px 50px 0px;
  }

  .review-title{
    text-align: center;
  }

  @media(min-width:768px){
  .review-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;

  }
  .review-flex1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

  }
}

  .review-box{
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
    margin: 20px;
    padding: 40px;
    border:1px solid #ededed;
  }

  .review-box> p{
    font-size:18px;
    line-height: 28px;
    font-family: "Raleway",sans-serif;
  }

  .review-name{
    font-size:20px;
    font-weight: 500;
    font-family: "Raleway",sans-serif;
  }

  .review-box img{
    margin-bottom: 20px;
  }

  /** video Box **/
  #video-box{
    padding: 50px 0px 50px 0px;
    background: linear-gradient(
      270deg, #F89748 -4.69%, #4A7BAA 106.25%);
  }

  .video-heading{
    font-size: 35px;
    font-family: "Raleway",sans-serif;
    line-height: 45px;
    text-align: left;
    color:white;
    flex:40%;
  }

  .video-flex{
    display: flex;
    justify-content: space-between;
  }

  .video-box{
    flex:60%;
  }
