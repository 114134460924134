#post-block{
  padding-top:80px;
  padding-bottom: 80px;
}


.postblock-render{
          margin-bottom:24px;
}
.postblock-render h2{
          font-size:20px;
          color: #1E2A5E;
          line-height: 28px;
}

.postblock-render h3{
          font-size:16px;
          color: #545252;
          font-weight: 400;
          line-height: 24px;

}


.postblock-grid{
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 40px;
          margin-bottom: 24px;
}

#inner-post{
          padding-top:80px;
          padding-bottom: 80px;
}

.inner-fullpost h1{

          font-size:45px;
          font-family: "Raleway", sans-serif;
          font-weight: 600;
          color: #1E2A5E;
          line-height: 56px;


}

.inner-fullpost h2{
          font-size:28px;
}

.inner-fullpost figure{
          margin: 0px 0px 15px 0px;
}