
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800');

h2{
  font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: inherit;
  color: #000000;
  }

  @media(max-width:768px){
    h2{
      font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: inherit;
  color: #000000;
    }
  }

  p{
    font-family: "Raleway",sans-serif;
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
  }

  h3{
    font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: capitalize;
  color: #000000;

  }


  /** Home Banner **/
@media(min-width:768px){
  .flex-hero{
    display: grid;
    grid-template-columns:50% 50%;
}
}

#heading{
  flex:50%;
}

.banner-img{
  flex:50%;
  text-align: right;
}
#hero-section{
  padding-top:80px;
  padding-bottom:80px;
}
.banner-heading {
  font-family: 'Raleway', Sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom:30px;
}

.banner-heading2{
  font-family: 'Raleway', Sans-serif;
  font-size: 45px;
  font-weight: 800;
  line-height: 55px;
  background: linear-gradient(270deg, #0E2132 -4.69%, #5186B8 50.62%);
  -webkit-background-clip: text;
  display: inline-block;
  -webkit-text-fill-color: #00000000;
  margin-bottom: 30px;
}

@media (max-width:768px){
  .banner-heading2{
    font-family: 'Raleway', Sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 55px;
    background: linear-gradient(
  270deg, #0E2132 -4.69%, #5186B8 50.62%);
      -webkit-background-clip: text;
      display: inline-block;
      -webkit-text-fill-color: #00000000;
      margin-bottom: 30px;
  }

  #heading{
    padding-top:0px;
  }

  #become-posp{
    margin-right:0px !Important;
  }

}
#become-posp{
  font-family: "Raleway", Sans-serif;
  width: auto;
  height: auto;
  background: #3F89C8;
  border:none;
  margin-right:18px;
  font-size: 18px;
  font-weight: 500;
  color:white;
  padding: 20px;
}

#insurance{
  width: auto;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  padding:20px;
}

@media (max-width:360px){
  #become-posp{
    margin-bottom: 24px;
    margin-right: 10px;
    margin-left:0px;
  }
}
@media (max-width:360px){
  #insurance{
    margin-bottom: 24px;
    margin-left:0px;
  }
}



/** Benfits section **/

#benefits-posphome{
  padding:50px 0px 50px 0px;
  background-color: white;
}

.posphome-heading{
  text-align: center;
}

@media(min-width:768px){
  .posp-flex{
    display: grid;
    text-align: center !important;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 60px;
  }
}

.posp-flex1{
  text-align: center;
  margin-bottom: 32px;
}

.posp-flex1>p{
  margin-left:10%;
  margin-right:10%;
}
.posp-flex1 img{
  margin-bottom: 24px;
}

#cta{
  padding:80px 0px 80px 0px;
  background: linear-gradient(270deg, #243B71 -4.69%, #3C80BF 106.25%);
}

#cta-button1{
  background-color: white;
  padding:15px 42px 15px 42px;
  font-size: 30px;
  font-weight: 500;
  font-family: "Ralway",sans-serif;
  border:none;
  border-radius: 5px;
  color: black;
}
.cta-button{
  text-align: center;
}

@media(min-width:768px){
.individual-group, .individual-group1{
  display:grid;
  grid-template-columns:20% 20% 20% 20% 20%;
  text-align: center;
  margin-bottom: 40px;
}
}


/** Insurance Plans **/

#insurance-plans{
  padding:50px 0px 50px 0px;
}

.individual-group{
  margin-bottom: 42px;
}

.individual-box{
  text-align: center;
  border: 1px solid #0ba4a6;
  padding: 28px;
  margin:10px;
  border-radius: 8px;
}

.individual-box img{
  width:50%;
  margin-bottom:20px;
}

.plans-head{
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 42px;
  line-height: 45px;
  font-weight:700;
  margin-bottom: 80px;
}
#individual, #business{
  font-family: "Raleway", Sans-serif;
  width: 256px;
  height: 54px;
  margin-bottom: 20px;
  background: #3F89C8;
  border-radius: 26px;
  border:none;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
}

/** WHy Samavesh Section **/

#why-samavesh{
  padding: 50px 0px 50px 0px;
  background-color: #E2E9F1;
}
.why-head{
  text-align: center;
  margin-bottom: 40px;
}

@media(min-width:768px){
.why-group{
  display:grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  text-align: center !important;
}
}

.why-box{
  text-align: center;
  margin-bottom:42px;
}

.why-box p{
margin-right: 15%;
margin-left:15%;
}

.why-box img{
  width:40%;
  margin-bottom: 32px;
}

@media(min-width:768px){
  .why-box img{
    width:30%
  }
}
#why-button{
font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: center;
color: #000000;
background-color: #faebd700;
border:none;
}

#featured-news{
  background: linear-gradient(270deg, rgba(11, 164, 166, 0.1) -4.69%, rgba(44, 188, 153, 0.1) 50.62%, rgba(97, 214, 142, 0.1) 106.25%);
  padding: 50px 0px 50px 0px;
}

.featured-head{
  text-align: center !important;
}


/** Gradient Stats Box **/

#grad{
  padding: 50px 0px 50px 0px;
  background: linear-gradient(
    270deg, #F89748 -4.69%, #4A7BAA 106.25%);
}
.grad-head>p{
  text-align: center;
  color: white;
}
.grad-heading{
  text-align: center;
  color: white;
}
.number-head{
  color:white;
}

@media(min-width:768px){
  .grad-group{
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
    color: white;
    margin-top: 100px;
  }
}

@media(min-width:768px){
  .grad-box1, .grad-box2{
    color:white;
    border-right: 5px solid white;
  }

}
.grad-box1, .grad-box2, .grad-box3{
  padding:50px 0px 50px 0px;
  text-align: center;
  color: white;
}

