.h12 {
          font-size: 42px;
          font-weight: 700;
          line-height:45px ;
          
          padding-top: 51px;
      }
      @media(max-width:768px){
      .h12 {
          font-size: 32px;
          padding-left:0;
          font-weight: 700;
          line-height:45px ;
          padding-top: 51px;
      }
      }
      .sub {
          font-size: 24px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 7px;
      }
      @media(min-width:768px){
      .sub {
          font-size: 18px;
          font-weight: 300;
          line-height: 42px ;
          padding-top: 7px;
      }
      }
      @media(min-width:768px){
      .para1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 33px;
      }
      }
      @media(min-width:768px){
      .para2 {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 54px;
      }
      }
      @media(min-width:768px){
      #privacy {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
         
      }
      }
      #privacy h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px ;
          padding-top: 54px;
      }