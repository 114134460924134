.logo {
          float: left;
          
      }
      @media(min-width:768px) {
          .logo {
              float: left;
              margin-left: 0px;
              max-width:100%;
          }
      }