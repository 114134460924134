
#about{
          padding-top:50px;
          padding-bottom: 50px;
}

.col-lg-12 h2{
          background: linear-gradient(270deg, #0E2132 -4.69%, #5186B8 50.62%);
}
#benefits-posp1 h2{
          text-align: center;
}

.benefitsposp-flex1{
          margin-bottom: 42px;
}


#business-leaders{
          padding-top: 50px;
          padding-bottom: 50px;
          text-align: center;
}

#business-leaders h2{
          text-align: center;
}
.team{
          text-align: center;
          padding: 20px;
}

#table-aboutus{
          padding-top: 50px;
          padding-bottom: 50px;
}

.table>tbody>tr>th, .table>tbody>tr>td{
          border: 1px solid #ddd;
          padding: 24px;
}

.table th{
          background-color: #FAFAFA;
}

.benefitsposp-flex1 img{
          width:100px
}

#about a{
          color: blue;
}