

#contact-banner{
          padding: 50px 0px 50px 0px;
}
@media(min-width:768px){
          .form-flex1{
                    display: flex;
                    justify-content: space-between;
          }
}

.contact-iconflex{
          display: flex;
          color: white;
          margin-bottom: 32px;
}

.contact-iconflex .fa{
    padding: 5px;
    font-size: 18px;
    width: 30px;
    border-radius:30px;
    text-align: center;
    text-decoration: none;
    margin-right: 15px;
    margin-top:5px;
    border:1px solid white;
}



.contact-head>p{
          font-size: 24px;
          font-weight:400;
          margin-right:50%;
          line-height: 32px;
          margin-bottom: 32px;
          margin-top: 32px;
}

@media(max-width:768px){
    .contact-head>p{

        margin-right: 0px;
}

}
.contactform-flex{
          flex: 60%;
          background-color: #EEEEEE;
          padding: 60px;


}
.form-heading{
          font-size: 22px;
          font-weight: 500;
}

.form_name1, .form_email1, .form_phone1, .form_message{
    padding:10px !important;
      font-family:'Raleway' !important;
      font-size:15px !important;
     color: #A1A1A1 !important;
      border: 1px solid black !important;
      border-radius:5px;
  }

  .conatactform> input[type=text]{
     width:100%;
  }


  .form_email1, .form_phone1{
      width:100% !important;
  }

  .conatactform> label{
      font-family: "Raleway", sans-serif;
      font-size: 16px;
      font-weight: 400;
  }


.form_message{
    width:100% !important;
}

.contact-information{
    background: linear-gradient(270deg, #243B71 -4.69%, #3C80BF 106.25%);
                    padding: 50px;
                    flex: 40%;
}

#contact-becomeposp{
          font-family: "Raleway", Sans-serif;
          background: #3F89C8;
          border:none;
          font-size: 18px;
          font-weight: 500;
          color:white;
          padding: 15px 42px 15px 42px;
          border-radius: 6px;
          margin-right: 15px;
}

#contact-enquiry{
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    fill: #898989;
    color: #898989;
    background-color: #A3A3A300;
    border-style: solid;
    border-width: 1px;
    border-color: #4D4D4D;
    border-radius: 5px;
    padding: 15px 62px 15px 62px;
    margin-left:15px;
}

.contact-head{
          margin-bottom: 40px;
}

.form-heading1{
    color: white;
    font-size: 22px;
          font-weight: 500;
}

.contact-information> p{
    color: white;
}

#form_button1{
    margin-top: 24px;
    width:auto;
    text-align: left;
    padding:12px 42px 12px 42px;
    background: #3F89C8;
    border:none;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    color: white;
}

.col-md-5{
    background-color:#3F89C8;
    padding: 40px;
    text-align: center;
    color: white;
}

.col-md-5 h2{
    font-size:22px;
    color: white;
}

.form_success{
    padding: 20px;
    border: 1px solid black;
    margin-top:150px;
}

.social-handles a {
    margin-right: 24px;
    color: #fff;
    font-size: 20px;
}