@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800');

#footer{
    padding-top: 50px;
    padding-bottom: 50px;
}
    .flex-row{
        border-top:10px solid #5186B8;
    }
@media(min-width:768px){
    .flex-row{
        display: flex;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
        padding-top:50px;
       }
}

    @media(min-width:768px){
        .flex-1, .flex-2{
            border-right:1px solid black;
        }
    }
    .flex-1{
        flex:2;
        display: flex;
        padding:20px;
    }

    .flex-11{
        margin-right:30%;
    }

    .flex-11, .flex-12, .flex-2, .flex-3 a{
        font-family: "Raleway", sans-serif;
        font-size: 18px;
        font-weight: 500;
    }

    .flex-11 h4, .flex-12 h4, .flex-2 h4, .flex-3 h4{
        font-family: "Raleway", sans-serif;
        font-size:18px;
        font-weight: 600;
    }

    .flex-2{
        flex:1;
        padding:20px;
    }

    .flex-3{
        flex:1;
        padding:20px;
    }

    ul{
        list-style:none;
        margin:0px;
        padding: 0px;
    }

    .social-links .fa{
        padding: 6px;
        font-size: 18px;
        width: 30px;
        border-radius:30px;
        text-align: center;
        text-decoration: none;
        margin: 5px 5px;
        border:1px solid black;
}

.footer-2 img{
    width:20%;
    margin-top:20px;
}

@media(max-width:768px){
    .footer-2 img{
        width:50%;
    }
}

.footer-2 h4{
    font-family: "Raleway", sans-serif;
    font-size:18px;
}

.footer-2 a{
    color: blue;
}
