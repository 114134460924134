@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);



h2{
  font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: inherit;
  color: #000000;
  }

  @media(max-width:768px){
    h2{
      font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: inherit;
  color: #000000;
    }
  }

  p{
    font-family: "Raleway",sans-serif;
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
  }

  h3{
    font-family: "Raleway", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 50px;
  /* identical to box height, or 107% */
  text-transform: capitalize;
  color: #000000;

  }


  /** Home Banner **/
@media(min-width:768px){
  .flex-hero{
    display: grid;
    grid-template-columns:50% 50%;
}
}

#heading{
  flex:50% 1;
}

.banner-img{
  flex:50% 1;
  text-align: right;
}
#hero-section{
  padding-top:80px;
  padding-bottom:80px;
}
.banner-heading {
  font-family: 'Raleway', Sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom:30px;
}

.banner-heading2{
  font-family: 'Raleway', Sans-serif;
  font-size: 45px;
  font-weight: 800;
  line-height: 55px;
  background: linear-gradient(270deg, #0E2132 -4.69%, #5186B8 50.62%);
  -webkit-background-clip: text;
  display: inline-block;
  -webkit-text-fill-color: #00000000;
  margin-bottom: 30px;
}

@media (max-width:768px){
  .banner-heading2{
    font-family: 'Raleway', Sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 55px;
    background: linear-gradient(
  270deg, #0E2132 -4.69%, #5186B8 50.62%);
      -webkit-background-clip: text;
      display: inline-block;
      -webkit-text-fill-color: #00000000;
      margin-bottom: 30px;
  }

  #heading{
    padding-top:0px;
  }

  #become-posp{
    margin-right:0px !Important;
  }

}
#become-posp{
  font-family: "Raleway", Sans-serif;
  width: auto;
  height: auto;
  background: #3F89C8;
  border:none;
  margin-right:18px;
  font-size: 18px;
  font-weight: 500;
  color:white;
  padding: 20px;
}

#insurance{
  width: auto;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  padding:20px;
}

@media (max-width:360px){
  #become-posp{
    margin-bottom: 24px;
    margin-right: 10px;
    margin-left:0px;
  }
}
@media (max-width:360px){
  #insurance{
    margin-bottom: 24px;
    margin-left:0px;
  }
}



/** Benfits section **/

#benefits-posphome{
  padding:50px 0px 50px 0px;
  background-color: white;
}

.posphome-heading{
  text-align: center;
}

@media(min-width:768px){
  .posp-flex{
    display: grid;
    text-align: center !important;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 60px;
  }
}

.posp-flex1{
  text-align: center;
  margin-bottom: 32px;
}

.posp-flex1>p{
  margin-left:10%;
  margin-right:10%;
}
.posp-flex1 img{
  margin-bottom: 24px;
}

#cta{
  padding:80px 0px 80px 0px;
  background: linear-gradient(270deg, #243B71 -4.69%, #3C80BF 106.25%);
}

#cta-button1{
  background-color: white;
  padding:15px 42px 15px 42px;
  font-size: 30px;
  font-weight: 500;
  font-family: "Ralway",sans-serif;
  border:none;
  border-radius: 5px;
  color: black;
}
.cta-button{
  text-align: center;
}

@media(min-width:768px){
.individual-group, .individual-group1{
  display:grid;
  grid-template-columns:20% 20% 20% 20% 20%;
  text-align: center;
  margin-bottom: 40px;
}
}


/** Insurance Plans **/

#insurance-plans{
  padding:50px 0px 50px 0px;
}

.individual-group{
  margin-bottom: 42px;
}

.individual-box{
  text-align: center;
  border: 1px solid #0ba4a6;
  padding: 28px;
  margin:10px;
  border-radius: 8px;
}

.individual-box img{
  width:50%;
  margin-bottom:20px;
}

.plans-head{
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 42px;
  line-height: 45px;
  font-weight:700;
  margin-bottom: 80px;
}
#individual, #business{
  font-family: "Raleway", Sans-serif;
  width: 256px;
  height: 54px;
  margin-bottom: 20px;
  background: #3F89C8;
  border-radius: 26px;
  border:none;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
}

/** WHy Samavesh Section **/

#why-samavesh{
  padding: 50px 0px 50px 0px;
  background-color: #E2E9F1;
}
.why-head{
  text-align: center;
  margin-bottom: 40px;
}

@media(min-width:768px){
.why-group{
  display:grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  text-align: center !important;
}
}

.why-box{
  text-align: center;
  margin-bottom:42px;
}

.why-box p{
margin-right: 15%;
margin-left:15%;
}

.why-box img{
  width:40%;
  margin-bottom: 32px;
}

@media(min-width:768px){
  .why-box img{
    width:30%
  }
}
#why-button{
font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: center;
color: #000000;
background-color: #faebd700;
border:none;
}

#featured-news{
  background: linear-gradient(270deg, rgba(11, 164, 166, 0.1) -4.69%, rgba(44, 188, 153, 0.1) 50.62%, rgba(97, 214, 142, 0.1) 106.25%);
  padding: 50px 0px 50px 0px;
}

.featured-head{
  text-align: center !important;
}


/** Gradient Stats Box **/

#grad{
  padding: 50px 0px 50px 0px;
  background: linear-gradient(
    270deg, #F89748 -4.69%, #4A7BAA 106.25%);
}
.grad-head>p{
  text-align: center;
  color: white;
}
.grad-heading{
  text-align: center;
  color: white;
}
.number-head{
  color:white;
}

@media(min-width:768px){
  .grad-group{
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
    color: white;
    margin-top: 100px;
  }
}

@media(min-width:768px){
  .grad-box1, .grad-box2{
    color:white;
    border-right: 5px solid white;
  }

}
.grad-box1, .grad-box2, .grad-box3{
  padding:50px 0px 50px 0px;
  text-align: center;
  color: white;
}



#hero-becomeposp{
          padding-top: 50px;
          padding-bottom: 50px;
}

@media(min-width:768px){
.flex-becomeposp{
  display: flex;
}
}
#posp-heading{
          flex: 60% 1;
}


.contactme{
          flex: 40% 1;
}

.form_name, .form_email, .form_phone{
  padding:10px !important;
	font-family:'Raleway' !important;
	font-size:20px !important;
   color: #A1A1A1 !important;
	border: 1px solid #61D68E !important;
}
#form_button{
  background: #F89748 !important;
border-radius: 5px;
	border:0px;
	font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.02em;
width:100%;
padding:16px;

color: #FFFFFF;
}

input[type=email], input[type=number],  input[type=tel], input[type=text]{
  width: 100%;
  margin-bottom: 24px;
}
.formWord{
  border: 1px solid #61d68e;
  margin:20px;
  padding: 35px;
  text-align: center;
}
.formWord>label{
  font-size:16px;
  font-weight:400;
  margin-top: 20px;
}

input[type=checkbox]{
  margin-right:10px;
}
/** Benfits of POSP **/

@media (min-width:768px){

  .benefitsposp-flex{
    display: grid;
    text-align: center !important;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 60px;
  }

}

  .benefitsposp-flex1{
    text-align: center;
  }

  .benefitsposp-heading{
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    line-height: 45px;
  }

  #benefits-posp{
    background-color: #E2E9F1;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .benefitsposp-flex1> p{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 18px;
    margin-top: 20px;
    font-family: "Raleway", sans-serif;
  }


 /* Steps Section */

#steps-posp{
  padding:50px 0px 50px 0px;
}

.stepposp-heading{
  text-align: center;
}
.steps{
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: #3F89C8;
  margin-top: -100px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

#step-button{
  background-color: #3F89C8;
  border:none;
  color: white;
  font-family: "Raleway", sans-serif;
  padding: 10px;
  border-radius: 6px;
}

@media(min-width:768px){
  .stepposp-gridA{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    text-align: center;
    margin-top: 50px;
    height: 300px;
  }

  .stepposp-gridB{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-right: 15%;
    margin-left: 15%;
    text-align: center;
  }

}


.stepposp-grid1{
  margin:60px;
  padding:60px;
  border: 1px solid #2cbc99;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #2CBC99;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 6px;
}

  /** review **/
  #review-section{
    padding: 50px 0px 50px 0px;
  }

  .review-title{
    text-align: center;
  }

  @media(min-width:768px){
  .review-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;

  }
  .review-flex1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

  }
}

  .review-box{
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
    margin: 20px;
    padding: 40px;
    border:1px solid #ededed;
  }

  .review-box> p{
    font-size:18px;
    line-height: 28px;
    font-family: "Raleway",sans-serif;
  }

  .review-name{
    font-size:20px;
    font-weight: 500;
    font-family: "Raleway",sans-serif;
  }

  .review-box img{
    margin-bottom: 20px;
  }

  /** video Box **/
  #video-box{
    padding: 50px 0px 50px 0px;
    background: linear-gradient(
      270deg, #F89748 -4.69%, #4A7BAA 106.25%);
  }

  .video-heading{
    font-size: 35px;
    font-family: "Raleway",sans-serif;
    line-height: 45px;
    text-align: left;
    color:white;
    flex:40% 1;
  }

  .video-flex{
    display: flex;
    justify-content: space-between;
  }

  .video-box{
    flex:60% 1;
  }

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif
}

#accordian-new h2 {
  text-align: center;
}

.accordion {
  font-family: "Raleway", sans-serif;
  font-weight:700px;
  font-size: 22px;
  margin: 0 20px;
  color: #000;
}

#accordian-new{
  padding-top: 50px;
  padding-bottom:50px;
}

.accordian-main{
  box-shadow: 0px 6px 24px rgb(27 27 27 / 20%);

}
.accordion__button {
  border: 0;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
  text-align: left;
  font-weight: 700;
  background-color: white;
}

.accordion__button span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
}

.accordion__button:focus {
  outline: none;
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  transition: all .5s;
  height: 0;
  margin: 0;
  margin-top: -10px;
  border: 1px solid #f1f1f1;
  border-top: 0;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
}

.form_name, .form_email, .form_phone, .select-insurance{
  padding:10px !important;
	font-family:'Raleway' !important;
	font-size:20px !important;
   color: #A1A1A1 !important;
	border: 1px solid #61D68E !important;
}
.form-1 button{
  background: #3F89C8 !important;
border-radius: 5px;
	border:0px;
	font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.02em;
width:100%;
padding:16px;

color: #FFFFFF;
}

input[type=email], input[type=number],  input[type=tel], input[type=text], select{
  width: 100%;
  margin-bottom: 24px;
}
.formWord{
  border: 1px solid #61d68e;
  margin:20px;
  padding: 35px;
  text-align: center;
}
.formWord>label{
  font-size:16px;
  font-weight:400;
  margin-top: 20px;
}

input[type=checkbox]{
  margin-right:10px;
}

label{
  font-weight: 400;
  font-family: "Raleway",sans-serif;
  display: contents;
}

label a{
  color:blue;
}

.form-1{
  text-align: left;
}
#benefits-posp1{
          background:#e8f7f6;
          padding: 50px 0px 50px 0px;
}


#contact-banner{
          padding: 50px 0px 50px 0px;
}
@media(min-width:768px){
          .form-flex1{
                    display: flex;
                    justify-content: space-between;
          }
}

.contact-iconflex{
          display: flex;
          color: white;
          margin-bottom: 32px;
}

.contact-iconflex .fa{
    padding: 5px;
    font-size: 18px;
    width: 30px;
    border-radius:30px;
    text-align: center;
    text-decoration: none;
    margin-right: 15px;
    margin-top:5px;
    border:1px solid white;
}



.contact-head>p{
          font-size: 24px;
          font-weight:400;
          margin-right:50%;
          line-height: 32px;
          margin-bottom: 32px;
          margin-top: 32px;
}

@media(max-width:768px){
    .contact-head>p{

        margin-right: 0px;
}

}
.contactform-flex{
          flex: 60% 1;
          background-color: #EEEEEE;
          padding: 60px;


}
.form-heading{
          font-size: 22px;
          font-weight: 500;
}

.form_name1, .form_email1, .form_phone1, .form_message{
    padding:10px !important;
      font-family:'Raleway' !important;
      font-size:15px !important;
     color: #A1A1A1 !important;
      border: 1px solid black !important;
      border-radius:5px;
  }

  .conatactform> input[type=text]{
     width:100%;
  }


  .form_email1, .form_phone1{
      width:100% !important;
  }

  .conatactform> label{
      font-family: "Raleway", sans-serif;
      font-size: 16px;
      font-weight: 400;
  }


.form_message{
    width:100% !important;
}

.contact-information{
    background: linear-gradient(270deg, #243B71 -4.69%, #3C80BF 106.25%);
                    padding: 50px;
                    flex: 40% 1;
}

#contact-becomeposp{
          font-family: "Raleway", Sans-serif;
          background: #3F89C8;
          border:none;
          font-size: 18px;
          font-weight: 500;
          color:white;
          padding: 15px 42px 15px 42px;
          border-radius: 6px;
          margin-right: 15px;
}

#contact-enquiry{
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    fill: #898989;
    color: #898989;
    background-color: #A3A3A300;
    border-style: solid;
    border-width: 1px;
    border-color: #4D4D4D;
    border-radius: 5px;
    padding: 15px 62px 15px 62px;
    margin-left:15px;
}

.contact-head{
          margin-bottom: 40px;
}

.form-heading1{
    color: white;
    font-size: 22px;
          font-weight: 500;
}

.contact-information> p{
    color: white;
}

#form_button1{
    margin-top: 24px;
    width:auto;
    text-align: left;
    padding:12px 42px 12px 42px;
    background: #3F89C8;
    border:none;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    color: white;
}

.col-md-5{
    background-color:#3F89C8;
    padding: 40px;
    text-align: center;
    color: white;
}

.col-md-5 h2{
    font-size:22px;
    color: white;
}

.form_success{
    padding: 20px;
    border: 1px solid black;
    margin-top:150px;
}

.social-handles a {
    margin-right: 24px;
    color: #fff;
    font-size: 20px;
}
.h12 {
          font-size: 42px;
          font-weight: 700;
          line-height:45px ;
          
          padding-top: 51px;
      }
      @media(max-width:768px){
      .h12 {
          font-size: 32px;
          padding-left:0;
          font-weight: 700;
          line-height:45px ;
          padding-top: 51px;
      }
      }
      .sub {
          font-size: 24px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 7px;
      }
      @media(min-width:768px){
      .sub {
          font-size: 18px;
          font-weight: 300;
          line-height: 42px ;
          padding-top: 7px;
      }
      }
      @media(min-width:768px){
      .para1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 33px;
      }
      }
      @media(min-width:768px){
      .para2 {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
          padding-top: 54px;
      }
      }
      @media(min-width:768px){
      #privacy {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px ;
         
      }
      }
      #privacy h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px ;
          padding-top: 54px;
      }

#about{
          padding-top:50px;
          padding-bottom: 50px;
}

.col-lg-12 h2{
          background: linear-gradient(270deg, #0E2132 -4.69%, #5186B8 50.62%);
}
#benefits-posp1 h2{
          text-align: center;
}

.benefitsposp-flex1{
          margin-bottom: 42px;
}


#business-leaders{
          padding-top: 50px;
          padding-bottom: 50px;
          text-align: center;
}

#business-leaders h2{
          text-align: center;
}
.team{
          text-align: center;
          padding: 20px;
}

#table-aboutus{
          padding-top: 50px;
          padding-bottom: 50px;
}

.table>tbody>tr>th, .table>tbody>tr>td{
          border: 1px solid #ddd;
          padding: 24px;
}

.table th{
          background-color: #FAFAFA;
}

.benefitsposp-flex1 img{
          width:100px
}

#about a{
          color: blue;
}
#post-block{
  padding-top:80px;
  padding-bottom: 80px;
}


.postblock-render{
          margin-bottom:24px;
}
.postblock-render h2{
          font-size:20px;
          color: #1E2A5E;
          line-height: 28px;
}

.postblock-render h3{
          font-size:16px;
          color: #545252;
          font-weight: 400;
          line-height: 24px;

}


.postblock-grid{
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 40px;
          margin-bottom: 24px;
}

#inner-post{
          padding-top:80px;
          padding-bottom: 80px;
}

.inner-fullpost h1{

          font-size:45px;
          font-family: "Raleway", sans-serif;
          font-weight: 600;
          color: #1E2A5E;
          line-height: 56px;


}

.inner-fullpost h2{
          font-size:28px;
}

.inner-fullpost figure{
          margin: 0px 0px 15px 0px;
}
.logo {
          float: left;
          
      }
      @media(min-width:768px) {
          .logo {
              float: left;
              margin-left: 0px;
              max-width:100%;
          }
      }
#footer{
    padding-top: 50px;
    padding-bottom: 50px;
}
    .flex-row{
        border-top:10px solid #5186B8;
    }
@media(min-width:768px){
    .flex-row{
        display: flex;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
        padding-top:50px;
       }
}

    @media(min-width:768px){
        .flex-1, .flex-2{
            border-right:1px solid black;
        }
    }
    .flex-1{
        flex:2 1;
        display: flex;
        padding:20px;
    }

    .flex-11{
        margin-right:30%;
    }

    .flex-11, .flex-12, .flex-2, .flex-3 a{
        font-family: "Raleway", sans-serif;
        font-size: 18px;
        font-weight: 500;
    }

    .flex-11 h4, .flex-12 h4, .flex-2 h4, .flex-3 h4{
        font-family: "Raleway", sans-serif;
        font-size:18px;
        font-weight: 600;
    }

    .flex-2{
        flex:1 1;
        padding:20px;
    }

    .flex-3{
        flex:1 1;
        padding:20px;
    }

    ul{
        list-style:none;
        margin:0px;
        padding: 0px;
    }

    .social-links .fa{
        padding: 6px;
        font-size: 18px;
        width: 30px;
        border-radius:30px;
        text-align: center;
        text-decoration: none;
        margin: 5px 5px;
        border:1px solid black;
}

.footer-2 img{
    width:20%;
    margin-top:20px;
}

@media(max-width:768px){
    .footer-2 img{
        width:50%;
    }
}

.footer-2 h4{
    font-family: "Raleway", sans-serif;
    font-size:18px;
}

.footer-2 a{
    color: blue;
}

.top-bar{
  background:linear-gradient(
    270deg, #243B71 -4.69%, #3C80BF 106.25%); ;
}

        .bg-myRed{
          background-color: crimson;
        }

        .content{
          padding: 12px;
          height: 3000px;
        }

        .coloring{
          color: black;
        }

        .navbar-brand{
                  height:auto;

        }

        .navbar-light .navbar-nav .nav-link{
                  color:black;
                  font-size: 16px;
                  font-weight: 600;
                  font-family: 'Raleway', sans-serif;
                  color: black;
        }

        .navbar-light .navbar-nav .nav-link:hover{
                  color:#3F89C8;
        }

        .navbar-light .navbar-nav .nav-link.active{
          color:#3F89C8;
        }

        .right-aligned{
                  justify-content: flex-end;
        }

        .navbar{
                  margin-bottom: 0px;
                  padding: 0px;
        }



      @media(max-width:768px){
        .navbar-brand{
          margin-right: 0px;
        }
      }
